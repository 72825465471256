:root {
  --bgColorDark: #131129;
  --fontColorWhite: #fff;
  --bluebg: #2f2cd8;
  --fontbodyColor: #7184ad;
  --bgColorlittleDark: #1d1933;
}

* {
  margin: 0;
  padding: 0;
}

/* Over Ride */

.form-control:focus {
  box-shadow: none !important;
}

/* 
------- SIGN UP ETC PAGES 
*/

#signup {
  background-color: var(--bgColorDark);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 200px;
}

#signup .rightSide {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 40px 20px;
}

#signup .leftSide {
  background-color: var(--bluebg);
  padding: 40px 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-image: url(https://media.licdn.com/dms/image/D5612AQGplp7JKG6Iiw/article-cover_image-shrink_720_1280/0/1673950361361?e=2147483647&v=beta&t=NxzErCoXqQ-xwkHJZZkKGKYNA21hJh3oNMUJzNKQr9M);
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  background-origin: content-box;
  /* height: 100%; */
}

#signup .leftSide div {
  padding: 0 30px;
}

#signup .rightSide .error {
  color: red;
  font-weight: 700;
  font-size: 12px;
}

#signup .alreadyHaveAccount p {
  color: var(--fontbodyColor);
  font-weight: 500;
}

#signup label {
  color: var(--fontbodyColor);
  font-weight: 500;
}

#signup input {
  color: #ffff;
  background-color: var(--bgColorDark);
}

/* 
----------- HOME PAGE 
*/

#homePage header {
  background-color: var(--bgColorDark);
  padding: 25px 40px;
  position: relative;
  width: 94%;
  /* float: right; */
  right: 0;
  position: absolute;
}

#homePage header a {
  text-decoration: none;
  font-size: 25px;
  font-weight: 600;
}

#homePage header .iconContainer>.icon {
  padding-left: 25px;
}

#homePage header .icon svg {
  font-size: 30px;
  color: #fff;
}

#homePage header .iconContainer .icon:nth-child(3) {
  background-color: var(--bluebg);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: relative;
  margin-left: 25px;
}

#homePage header .iconContainer div:nth-child(3) div {
  position: absolute;
  left: 25%;
}

#homePage header .notification {
  position: relative;
  cursor: pointer;
}

#homePage header .notification:hover .notificationContent {
  display: block;
}

#homePage header .notification .notificationContent {
  background-color: var(--bgColorlittleDark);
  position: absolute;
  /* padding: 10px; */
  top: 40px;
  left: -100px;
  width: 150px;
  z-index: 1;
  box-shadow: 10px 10px 23px -6px rgb(47 44 216);
  display: none;
}

#homePage header .notification .notificationContent a {
  padding: 10px 20px;
  text-decoration: none;
  width: 100%;
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

#homePage header .notification .notificationContent>a:not(:last-child) {
  border-bottom: 1px solid #ddd;
  border-color: rgba(255, 255, 255, 0.08);
}

#homePage header .notification .notificationContent>a:hover {
  background-color: var(--bluebg);
}

/* 
------- Side NAVIGATION .belowLeftSide
*/

#homePage .belowLeftSide {
  /* height: 100vh; */
  background-color: var(--bluebg);
  width: 6%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#homePage .belowLeftSide>div {
  /* width: 100%; */
  padding: 20px 43px;
}

#homePage .belowLeftSide .secondContainer nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#homePage .belowLeftSide a {
  /* font-size: 40px; */
  text-decoration: none;
}

#homePage .belowLeftSide nav {
  margin-top: 80%;
}

#homePage .belowLeftSide nav>div {
  margin-bottom: 25px;
}

#homePage .belowLeftSide .icon svg {
  font-size: 35px;
  color: #fff;
}

/* 
------- Side NAVIGATION .belowRightSide
*/
#homePage .belowRightSide {
  /* float: right; */
  min-height: 100vh;
  width: 94%;
  background-color: var(--bgColorDark);
  margin-top: 80px;
  padding: 20px 30px;
}

#homePage .belowRightSide .breadcrumbs a {
  color: #7184ad;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
}

#homePage .belowRightSide .breadcrumbs svg {
  margin: 0 10px;
  font-size: 20px;
  color: #7184ad;
}

/*
---------------  DASHBOARD  
*/

#homePage .cardContainer1 {
  margin-top: 30px;
}

#homePage .cardContainer1 div .card {
  background-color: var(--bgColorlittleDark);
  padding: 20px 20px 5px 20px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 6px;
}

#homePage .cardContainer1 div .card h6 {
  font-size: 14px;
}

#homePage .cardContainer1 div .card .divider {
  border-top: 1px solid rgb(229, 234, 239);
  border-color: rgba(255, 255, 255, 0.08);
  margin-top: 10px;
  padding-top: 10px;
}

#homePage .cardContainer2 {
  margin-top: 30px;
}

#homePage .cardContainer2 .card {
  padding: 10px;
  border: 1px solid #ffffff14;
  background-color: var(--bgColorlittleDark);
  color: #fff;
}

#homePage .cardContainer2 .expenseBreakdown:not(:last-child) {
  border-bottom: 1px solid #ffffff14;
  padding: 0px 10px;
}

/* 
---------------------------- RESPONSIVE BREAKPOINTS 
*/

/* xl */
@media only screen and (max-width: 1200px) {
  #signup {
    /* background-color: yellow; */
    padding: 50px 100px;
  }
}

/* lg */
@media only screen and (max-width: 992px) {
  #signup {
    /* background-color: red; */
    padding: 50px;
  }

  #homePage .belowRightSide {
    margin-top: 90px;
  }


}

/* md */
@media only screen and (max-width: 768px) {
  #signup {
    /* background-color: green; */
    height: auto;
    padding: 20px;
  }

  #signup .leftSide {
    border-top-right-radius: 10px;
  }

  #signup .rightSide {
    margin-top: -20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  #homePage header {
    width: 100%;
    padding: 15px 20px;

  }

  #homePage .belowRightSide {
    padding: 20px 15px;
  }

  #homePage .belowLeftSide {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* height: 60px; */
    z-index: 1;
    align-items: inherit !important;

  }

  #homePage .belowLeftSide>div {
    padding: 0px;
  }

  #homePage .belowLeftSide .firstContainer {
    display: none;
  }

  #homePage .belowLeftSide .secondContainer nav {
    /* background-color : red; */
    margin-top: 0px;
    flex-direction: row;
    justify-content: space-between !important;
    /* align-items: justify-content!important; */
    padding: 10px 20px;
  }

  #homePage .belowLeftSide nav>div {
    margin-bottom: 0;
  }

  #homePage .belowRightSide {
    margin-top: 70px;
    width: 100%;
  }

}

/* sm */
@media only screen and (max-width: 576px) {
  #signup {
    /* background-color: black; */
    height: auto;
    padding: 20px;
  }

  #signup .leftSide {
    height: 200px;
    border-top-right-radius: 10px;
  }

  #signup .rightSide {
    margin-top: -20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  #homePage .belowRightSide {
    margin-top: 95px;
  }

}